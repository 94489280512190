import './About.css';
import logo from '../../assets/Logotype-Riksforbundet-Svensk-Tradgard.svg';

function About() {

  return (
    <div className='about'>
      <img 
        className='logo'
        src={logo} 
        alt="Riksförbundet Svensk Trädgård" 
        width='140'
        height='81'
      />
      <h3>Zonkartan</h3>
      <p>Riksförbundet Svensk Trädgårds karta för odlingszoner av träd och buskar.</p>
      <p>
        <a 
          href="https://svensktradgard.se/zonkartan"
          target="_blank">
            Mer info
        </a>
      </p>
    </div>
  );
}

export default About;