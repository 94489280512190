import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import './Search.css';
import Autocomplete from "@material-ui/lab/Autocomplete";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import axios from "axios";

const useStyles = makeStyles((theme) => ({
  icon: {
      color: theme.palette.text.secondary,
      marginRight: theme.spacing(2),
  },
}));

function Search({ 
    autocompleteService, 
    geocoder,
    setPointOfInterest, 
    value, 
    setValue,
    searchHasFocus,
    setSearchHasFocus,
 }) {
  const classes = useStyles();
  
  const [inputValue, setInputValue] = useState(null);
  const [options, setOptions] = useState([]);

  


  const fetch = useMemo(
    () =>
        throttle((request, callback) => {
            autocompleteService.current.getPlacePredictions(
                request,
                callback
            );
        }, 200),
    []
  );

  useEffect(() => {
    let active = true;
    if (!autocompleteService.current) {
        return undefined;
    }
    if (inputValue === "") {
        setOptions(value ? [value] : []);
        return undefined;
    }

    fetch(
        { input: inputValue, componentRestrictions: { country: "se" } },
        (results) => {
            if (active) {
                let newOptions = [];
                if (value) {
                    newOptions = [value];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                // console.log(newOptions);
                setOptions(newOptions);
            }
        }
    );
    return () => {
        active = false;
    };
}, [value, inputValue, fetch]);




  return (
    <Autocomplete
      id="places-autocomplete"
      style={{
          position: "absolute",
          top: 10,
          left: 10,
          width: 'calc(100% - 20px)',
      }}
      classes={{
          popper: 'my-popper',
      }}
      onFocus={() => setSearchHasFocus(true)}
      onBlur={() => setSearchHasFocus(false)}
      blurOnSelect={true}
      getOptionLabel={(option) =>
          typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        // Geocode place
        // console.log(newValue?.description);
        // console.log(encodeURIComponent(newValue?.description));
        if (newValue) {
            geocoder.current.geocode( { 'address': newValue?.description.replace(" ", "+")}, function(results, status) {
                if (status == 'OK') {
                    const location = results[0]?.geometry?.location;
                    if (location) {
                        setPointOfInterest(location);
                    }
                } else {
                    console.log('Geocode was not successful for the following reason: ' + status);
                }
            });
        }
        // if (newValue) {
        //   const response = axios
        //       .get(
        //           "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        //               newValue?.description.replace(" ", "+") +
        //               "&key=" +
        //               process.env.REACT_APP_GOOGLE_MAPS_API_KEY
        //       )
        //       .then((result) => {
        //           // console.log(result.data.results[0].geometry.location);
        //           // console.log(result.data.results[0]);
        //           const location =
        //               result.data?.results[0]?.geometry?.location;
        //           if (location) {
        //             setPointOfInterest(location);
        //           }
        //       })
        //       .catch((error) => {
        //           console.error(error);
        //       })
        //       ;
        // }
      }}
      onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
      }}
      renderInput={(params) => (
          <div ref={params.InputProps.ref}>
              <input
                  placeholder="Sök plats"
                  type='text'
                  {...params.inputProps}
                  className={`input-search ${(value || searchHasFocus) && 'input-search-has-value'}`}
              />
          </div>
          
      )}
      renderOption={(option) => {
          const matches =
              option.structured_formatting
                  .main_text_matched_substrings;
          const parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                  match.offset,
                  match.offset + match.length,
              ])
          );
          return (
              <Grid container alignItems="center" style={{}}>
                  <Grid item>
                      <LocationOnIcon className={classes.icon} />
                  </Grid>
                  <Grid item xs>
                      {parts.map((part, index) => (
                          <span
                              key={index}
                              style={{
                                  fontWeight: part.highlight
                                      ? 700
                                      : 400,
                              }}
                          >
                              {part.text}
                          </span>
                      ))}
                      <Typography
                          variant="body2"
                          color="textSecondary"
                      >
                          {
                              option.structured_formatting
                                  .secondary_text
                          }
                      </Typography>
                  </Grid>
              </Grid>
          );
      }}
  />
  );
}

export default Search;