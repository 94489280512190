import './ZoneScale.css';

function ZoneScale() {

  const els = [<li key={'zone-0'} className='zone-0'>fjäll</li>];

  for (let i=8; i>0; i--) {
    els.push(
      <li key={`zone-${i}`} className={`zone-${i}`}>
        {i}
      </li>);
  }

  return (
    <div className='zone-scale-container'>
      
      <p className='title'>Odlings-<br />zoner</p>

      <ul>
        {els}
      </ul>
      
    </div>
  ); 
}

export default ZoneScale;
