import {
  useState,
  useRef,
} from 'react';
import './Modal.css';
import LogoButton from '../LogoButton/LogoButton';
import Button from '../Button/Button';
import iconClose from '../../assets/close_black_24dp.svg';

function Modal({ children, visible, close }) {

  const ref = useRef();
  
  const handleBgClick = (event) => {
    if (event.target === ref.current) {
      close();
    }
  };

  let modalClass = 'modal';
  if (visible) modalClass += ' visible';

  return (
    <div 
      className={modalClass}
      onClick={handleBgClick}
      ref={ref}
    >
      <div className='modal-content'>
        <div className='btn-close'>
        <Button
          icon={iconClose}
          callback={close}
        />
        </div>
        {children}
      </div>
    </div>
  );
}

export default Modal;