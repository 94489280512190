import React, {
    useEffect,
    useRef,
    useState,
    useCallback,
} from "react";

import "./App.css";
import {
    GoogleMap,
    useJsApiLoader,
} from "@react-google-maps/api";

import { InfoBox } from "@react-google-maps/api";
import { Marker } from "@react-google-maps/api";

const zone1 = require("./zone-1.json");
const zone2 = require("./zone-2.json");
const zone3 = require("./zone-3.json");
const zone4 = require("./zone-4.json");
const zone5 = require("./zone-5.json");
const zone6 = require("./zone-6.json");
const zone7 = require("./zone-7.json");
const zone8 = require("./zone-8.json");
const zoneFjall = require("./zone-fjall.json");



const zoneMeta = {
    'zone-1': {
        name: "Zon 1",
        color: "#be3a30bf",
    },
    'zone-2': {
        name: "Zon 2",
        color: "#ff8900",
    },
    'zone-3': {
        name: "Zon 3",
        color: "#f8ac64",
    },
    'zone-4': {
        name: "Zon 4",
        color: "#af9a5d",
    },
    'zone-5': {
        name: "Zon 5",
        color: "#89a88d",
    },
    'zone-6': {
        name: "Zon 6",
        color: "#afa1b4",
    },
    'zone-7': {
        name: "Zon 7",
        color: "#4790cc",
    },
    'zone-8': {
        name: "Zon 8",
        color: "#a6c2e5",
    },
    'zone-fjall': {
        name: "Fjäll",
        color: "#fff",
    },
};


const getWidth = () => (
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth
  );

const getHeight = () => (
    // window.innerHeight ||
    // document.documentElement.clientHeight ||
    // document.body.clientHeight
    document.documentElement.clientHeight
);

function useCurrentSize() {
    let [width, setWidth] = useState(getWidth());
    let [height, setHeight] = useState(getHeight());

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                setWidth(getWidth());
                setHeight(getHeight());
            }, 150);
            
        };
        window.addEventListener("resize", resizeListener);
        return () => {
            window.removeEventListener("resize", resizeListener);
        };
    }, []);
    return {width, height};
}

const center = {
    lat: 62.85443013631183,
    lng: 16.71242282099435,
};

const libraries = ["geometry", "places"];

function Map({ setAutocompleteService, setGeocoder, pointOfInterest, clearSearch }) {
    const [map, setMap] = useState(null);
    
    const mapRef = useRef();
    const polygons = useRef([]);
    let size = useCurrentSize();
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
        libraries: libraries,
    });

    const [infoBoxLocation, setInfoBoxLocation] = useState(null);
    const [infoBoxLabel, setInfoBoxLabel] = useState("");
    const [infoBoxColor, setInfoBoxColor] = useState('yellow');

    useEffect(() => {
        if (pointOfInterest) {
            map.panTo(pointOfInterest);
            // map.setZoom(8);
            setInfoBoxLocation(pointOfInterest);
            findZone(
                new window.google.maps.LatLng(
                    pointOfInterest.lat(),
                    pointOfInterest.lng(),
                )
            );
        }
    }, [pointOfInterest]);

    const handleMapClick = (event) => {
        // polygons.current.forEach((polygon) => {
        // console.log(
        //     window.google.maps.geometry.poly.containsLocation(
        //         event.latLng,
        //         polygon
        //     )
        // );
        // });
    };

    const addPolygonsFromGeometry = (geometry, name) => {
        // console.log(geometry);
        geometry.getArray().forEach((zonePart) => {
            const polygonPaths = [];
            zonePart.getArray().forEach((paths) => {
                // console.log(paths);
                polygonPaths.push(paths.getArray());
            });
            const polygon = new window.google.maps.Polygon({
                paths: polygonPaths,
                name: name,
                // map: map,
                // clickable: false,
            });
            polygons.current.push(polygon);
        });
    };

    const onUnMount = useCallback((map) => {
        setMap(null);
    }, []);

    const onMapLoad = useCallback((map) => {
        // autocompleteService.current =
        //     new window.google.maps.places.AutocompleteService();
        
        setAutocompleteService(new window.google.maps.places.AutocompleteService());
        
        setGeocoder(new window.google.maps.Geocoder());
        
        

        const bounds = new window.google.maps.LatLngBounds();
        bounds.extend(
            new window.google.maps.LatLng(68.74868896525182, 20.994163118394102)
        );
        bounds.extend(
            new window.google.maps.LatLng(55.79565754368366, 13.85304987403346)
        );
        bounds.extend(
            new window.google.maps.LatLng(59.92221730608628, 11.688559723632553)
        );
        bounds.extend(
            new window.google.maps.LatLng(65.99171342173211, 23.220916656634994)
        );

        map.fitBounds(bounds);

        setMap(map);

        // console.log(window.google.maps);
        map.data.addGeoJson(zone1);
        map.data.addGeoJson(zone2);
        map.data.addGeoJson(zone3);
        map.data.addGeoJson(zone4);
        map.data.addGeoJson(zone5);
        map.data.addGeoJson(zone6);
        map.data.addGeoJson(zone7);
        map.data.addGeoJson(zone8);
        map.data.addGeoJson(zoneFjall);

        const zoneData = new window.google.maps.Data();
        zoneData.addGeoJson(zone1, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zone2, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zone3, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zone4, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zone5, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zone6, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zone7, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zone8, {
            idPropertyName: "name",
        });
        zoneData.addGeoJson(zoneFjall, {
            idPropertyName: "name",
        });

        const z1 = zoneData.getFeatureById("zone-1");
        const z2 = zoneData.getFeatureById("zone-2");
        const z3 = zoneData.getFeatureById("zone-3");
        const z4 = zoneData.getFeatureById("zone-4");
        const z5 = zoneData.getFeatureById("zone-5");
        const z6 = zoneData.getFeatureById("zone-6");
        const z7 = zoneData.getFeatureById("zone-7");
        const z8 = zoneData.getFeatureById("zone-8");
        const zFjall = zoneData.getFeatureById("zone-fjall");

        addPolygonsFromGeometry(z1.getGeometry(), z1.getProperty("name"));
        addPolygonsFromGeometry(z2.getGeometry(), z2.getProperty("name"));
        addPolygonsFromGeometry(z3.getGeometry(), z3.getProperty("name"));
        addPolygonsFromGeometry(z4.getGeometry(), z4.getProperty("name"));
        addPolygonsFromGeometry(z5.getGeometry(), z5.getProperty("name"));
        addPolygonsFromGeometry(z6.getGeometry(), z6.getProperty("name"));
        addPolygonsFromGeometry(z7.getGeometry(), z7.getProperty("name"));
        addPolygonsFromGeometry(z8.getGeometry(), z8.getProperty("name"));
        addPolygonsFromGeometry(
            zFjall.getGeometry(),
            zFjall.getProperty("name")
        );

        map.data.setStyle(function (feature) {
            var name = feature.getProperty("name");
            const color = zoneMeta[name]?.color ? zoneMeta[name]?.color : 'gray';
            return {
                fillColor: color,
                fillOpacity: 0.45,
                strokeColor: "gray",
                strokeWeight: 1,
                strokeOpacity: 1.0,
            };
        });

        map.data.addListener("click", (event) => {
            // const name = event.feature.getProperty("name");
            // console.log(name);
            // console.log(event.latLng);
            findZone(event.latLng);
            clearSearch();
        });

        setMap(map);
    }, []);

    const findZone = useCallback((latLng) => {
        polygons.current.forEach((polygon) => {
            // console.log(polygon.name);

            if (
                window.google.maps.geometry.poly.containsLocation(
                    latLng,
                    polygon
                )
            ) {
                setInfoBoxLabel(zoneMeta[polygon.name].name);
                setInfoBoxLocation({ lat: latLng.lat(), lng: latLng.lng() });
                setInfoBoxColor(zoneMeta[polygon.name].color);
            }
        });
    }, []);

    

    
    return isLoaded ? (
            <GoogleMap
                onLoad={onMapLoad}
                ref={(ref) => (mapRef.current = ref)}
                mapContainerStyle={{
                    width: "100%",
                    height: size.height + "px",
                }}
                disableDefaultUI={true}
                options={{
                    disableDefaultUI: true,
                    scaleControl: true,
                    zoomControl: size.width >= 768,
                }}
                center={center}
                zoom={6}
                onClick={handleMapClick}
                onUnmount={onUnMount}
            >
                <Marker
                    position={infoBoxLocation ? infoBoxLocation : center}
                    // onClick={props.onToggleOpen}
                    visible={infoBoxLocation ? true : false}
                >
                    {true && (
                        <InfoBox
                            // onCloseClick={props.onToggleOpen}
                            visible={infoBoxLocation ? true : false}
                            options={{
                                closeBoxURL: ``,
                                enableEventPropagation: true,
                                boxStyle: { 
                                    borderRadius: '8px',
                                    boxShadow: '0 0 5px 0 #00000044',
                                },
                            }}
                        >
                            <>
                            <div
                                style={{
                                    backgroundColor: '#fff',
                                    opacity: 1.0,
                                    padding: `4px`,
                                    borderRadius: 8,
                                    width: 55,
                                    height: 20,
                                    border: '2px solid #fff',
                                }}
                            />
                            <div
                                style={{
                                    backgroundColor: infoBoxColor,
                                    padding: `4px`,
                                    borderRadius: 8,
                                    width: 55,
                                    height: 20,
                                    border: '2px solid #444',
                                    position: 'absolute',
                                    top: 0,
                                }}
                            >
                                <div
                                    style={{
                                        fontSize: `16px`,
                                        fontWeight: "bold",
                                        color: '#444',
                                        width: 55,
                                        height: 20,
                                        textAlign: "center",
                                    }}
                                >
                                    {infoBoxLabel}
                                </div>
                            </div>
                            </>
                        </InfoBox>
                    )}
                </Marker>
            </GoogleMap>

    ) : null;
}

export default Map;
