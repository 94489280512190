import './LogoButton.css';
import Logo from '../../assets/Logotype-Riksforbundet-Svensk-Tradgard.svg';

function LogoButton({ callback }) {

  return (
    <div className='logo-button-container'
      onClick={callback}
    >
      <img src={Logo} alt="Rikförbundet Svensk Trädgård" width='68' height='40' />
    </div>
  );
}

export default LogoButton;