import './Button.css';


function Button({ callback, icon, children }) {

  return (
    <div 
      className='button'
      onClick={callback}
    >
      {
        icon && <img src={icon} />
      }
    </div>
  );
}

export default Button;