import './InfoBox.css';

function InfoBox({ children }) {

  return (
    <div className='info-box'>
      {children}
    </div>
  );
}

export default InfoBox;