
import { 
  useState,
  useRef,
  useCallback,
} from 'react';
import './App.css';
import Map from './Map';
import Search from './components/Search/Search';
import ZoneScale from './components/ZoneScale/ZoneScale';
import LogoButton from './components/LogoButton/LogoButton';
import Modal from './components/Modal/Modal';
import InfoBox from './components/InfoBox/InfoBox';
import About from './components/About/About';

const getWidth = () => (
  window.innerWidth ||
  document.documentElement.clientWidth ||
  document.body.clientWidth
);

const isSmallDevice = () => (
  getWidth() < 768
 );

function App() {

  const metaEl = `<meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1">`;
  document.head.insertAdjacentHTML("beforeend", metaEl);

  
  
  // Map variables.
  const autocompleteService = useRef(null);
  const setAutocompleteService = (service) => {
    autocompleteService.current = service;
  }
  const geocoder = useRef(null);
  const setGeocoder = (coder) => {
    geocoder.current = coder;
  }
  const [pointOfInterest, setPointOfInterest] = useState(null);

  // Search variables
  const [searchHasFocus, setSearchHasFocus] = useState(false);
  const [searchValue, setSearchValue] = useState(null);
  const clearSearch = useCallback(() => {
    setSearchValue(null);
}, []);


  const [aboutModalVisible, setAboutModalVisible] = useState(isSmallDevice());
  const closeAboutModal = () => {
    setAboutModalVisible(false);
  }
  const openAboutModal = () => {
    setAboutModalVisible(true);
  }

  return (
    <>

    <Map 
      setAutocompleteService={setAutocompleteService}
      setGeocoder={setGeocoder}
      pointOfInterest={pointOfInterest}
      clearSearch={clearSearch}
    />
    
    <div className='info-container-large'>
      <InfoBox>
        <About />
      </InfoBox>
      <div>
        <ZoneScale />
      </div>
    </div>
      
    <div className={`info-container-small ${searchHasFocus && 'hide'}`}>
      <ZoneScale />
      <LogoButton callback={openAboutModal} />
    </div>

    <Search 
      autocompleteService={autocompleteService}
      geocoder={geocoder}
      setPointOfInterest={setPointOfInterest}
      value={searchValue}
      setValue={setSearchValue}
      searchHasFocus={searchHasFocus}
      setSearchHasFocus={setSearchHasFocus}
    />

    <div className='modal-container'>
      <Modal 
        visible={aboutModalVisible}
        close={closeAboutModal}
      >
        <About />
      </Modal>
    </div>
      
    </>
 
  );
}

export default App;
